<template>
  <div>
      <b-form-group
        id="dt_combo"
        description=""
        label="Device Type"
        :label-cols="lpos"
        label-for="dt_select"
        :state="null">
          <template v-slot:label>
             <b>Device Type</b>
          </template>
          <model-list-select id="dt_select" name="dt_select" :list="options" v-model="selected" placeholder="Select" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'DeviceTypeFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: [String, Number], default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: '',
    options: [
      { id: 1, label: 'Samsung Tab A' },
      { id: 2, label: 'GenKey Tablet' },
      { id: 3, label: 'Bio-Scanner' },
      { id: 4, label: 'Power Bank' },
      { id: 5, label: 'USB Connector' }
    ]
  }),
  computed: {
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.id
    this.options = this.options.sort(function (a, b) {
      return a.label.localeCompare(b.label)
    })
    this.options.unshift({ id: 0, label: 'All' })
  },
  methods: {
    sendUpdate () {
      if (!this.first) {
        this.$emit('dt_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
