<template>
  <div style="margin:0; padding:0">
    <PageHeader subtitle="Manage Devices" :title="title" />

    <div class="row">
        <div class="col">
          <div class="card card-small mb-4">

            <div class="card-header border-bottom">
              <div class="row">
                    <div class="col-sm-3">
                     <DeviceTypeFilter v-on:dt_change="filterByDevice" />
                    </div>
                    <div class="col-sm-3">
                     <AssignFilter v-on:assigned_change="filterByStatus" :id="'unassigned'" />
                    </div>
                    <div class="col-sm-3">
                      <div class="btn-group float-right" data-toggle="buttons" aria-label="">
                          <el-input v-model="tableFilteredData[0].value" placeholder="Search"
                              prefix-icon="el-icon-search" size="medium" style="width: 250px"></el-input>
                      </div>
                    </div>
                    <div class="col-sm-3 hidden-sm-down">
                        <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                          <el-button type="primary" size="mini" @click="createDialogVisible = true"><i class="el-icon-plus el-icon--left"></i> Create Kit</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body p-4 pb-3">

              <data-tables-server
                :loading='busy'
                :data='tableData'
                :total='tableTotal'
                :filters="tableFilteredData"
                highlight-current-row
                @query-change="get"
                :table-props='tableProps'>

                <el-table-column v-for="(item, idx) in visibleFields" :key="idx" :prop="item.prop" :label='item.label' :sortable='item.sortable'>
                  <template slot-scope="scope">
                      <span v-if="item.prop === 'kitCode'">
                          {{ scope.row.kitCode !== null ? scope.row.kitCode : 'Not part of kit' }}
                      </span>
                      <span v-else-if="item.prop === 'fullname'">
                          {{ scope.row.fullname ? scope.row[item.prop] : 'Not assigned' }}
                      </span>
                      <span v-else>{{ scope.row[item.prop] }}</span>
                  </template>
                </el-table-column>

              </data-tables-server>
            </div>

          </div>
        </div>
    </div>


    <el-dialog title="Create a kit"  :visible.sync="createDialogVisible" width="30%">
      <SelectDevicesMultiple v-model="device_kit" :opening="createDialogVisible" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="createDialogVisible = false">Cancel</b-button>
        <b-button size="sm" class="" variant="primary" :disabled="createDisabled" @click="doCreateKit()">Create Kit</b-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { DataTablesServer } from 'vue-data-tables'
let numeral = require('numeral')

import deviceService from '../../api/devices'
import PageHeader from '../../containers/PageHeader'
import AssignFilter from '../../components/filters/AssignedFilter'
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter'
import SelectDevicesMultiple from '../../components/selectors/SelectDevicesMultiple'

Vue.use(DataTablesServer)
Vue.use(require('vue-moment'))

export default {
  name: 'DeviceList',
  components: {
    PageHeader, AssignFilter, DeviceTypeFilter, SelectDevicesMultiple
  },
  data () {
    return {
      busy: true,
      createDialogVisible: false,
      sortProps: { order: 'ascending', prop: 'kitCode' },
      tableData: [],
      tableTotal: 0,
      tableFields: [
        { prop: 'ID', label: 'ID', visible: false, sortable: 'custom' },
        { prop: 'kitCode', label: 'Kit Code', visible: true, sortable: true },
        { prop: 'GNHRCode', label: 'GNHR Code', visible: true, sortable: true },
        { prop: 'device_type', label: 'Type', visible: true, sortable: true },
        { prop: 'IMEI', label: 'IMEI', visible: true, sortable: true },
        { prop: 'Serial', label: 'Serial #', visible: true, sortable: true },
        { prop: 'fullname', label: 'Assigned to', visible: false, sortable: true },
      ],
      tableFilteredData: [
        {
          value: '',
          prop: ['ID', 'type', 'serial', 'imei', 'fullname']
        }
      ],
      tableProps: { style: 'width: 99%', emptyText: 'No devices found', stripe: true, defaultSort: this.sortProps },
      viewIgnoreFields: [
        'emailAddress', 'ezwich', 'fullname', 'phone'
      ],
      deviceFilter: '',
      assignFilter: '',
      device_kit: {},
      queryProps: { page: 1, pageSize: 10, sort: this.sortProps, search: '', status: '', device: '' }
    }
  },
  created () {
    this.get()
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      isAdmin: 'isAdmin',
      user: 'getUser'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    title: function () {
      return 'Devices (' + numeral(this.tableTotal).format('0,0') + ')'
    },
    createDisabled: function () {
      return Object.keys(this.device_kit).length < 3
    }
  },
  methods: {
    get (queryInfo = {}) {
      let self = this
      this.busy = true
      this.queryProps = queryInfo
      let params = this.getParams(queryInfo)
      deviceService.getDevices(params)
        .then((response) => {
          self.rawData = response.data
          self.tableData = response.data.map(function (s) {
            return self.getSimpleRow(s)
          })
          self.tableTotal = response.meta.total
          self.busy = false
        })
        .catch((error) => {
          this.busy = false
          this.handleError(error)
        })
    },
    doCreateKit () {
      let self = this
      let p = this.device_kit
      p.createuserid = this.user.userID
      p.firm_id = this.user.firm_id
      deviceService.addDeviceKit(p)
        .then(() => {
          self.device_kit = {}
          self.createDialogVisible = false
          self.$message({
            type: 'success', message:  'Kit added.'
          })
          self.get()
        })
        .catch((error) => {
          this.handleError(error)
        })
    },
    filterByStatus (v) {
      this.assignFilter = v.value
      this.get(this.queryProps)
    },
    filterByDevice (v) {
      this.deviceFilter = v.value
      this.get(this.queryProps)
    },
    getParams (info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : this.sortProps.prop,
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : this.sortProps.order,
        search: (info.type === 'filter') ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : '',
        status: this.assignFilter === 0 ? '' : this.assignFilter,
        device: this.deviceFilter === 0 ? '' : this.deviceFilter
      }
    },
    getSimpleRow (item) {
      let x = {}
      for(let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) && is.not.null(item[i.prop]) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && is.not.null(item[i.prop]) ? numeral(item[i.prop]).format('0,0') : x[i.prop]
      }
      return x
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white!important;
}
.text-light {
  color: white!important;
}
</style>