<template>
  <div style="margin:0; padding:0">
    <PageHeader subtitle="Manage Device kits" :title="title" />

    <div class="row">
        <div class="col">
          <div class="card card-small mb-4">

            <div class="card-header border-bottom">
              <div class="row">
                    <div class="col-sm-2" v-if="isGnhr">
                     <FirmFilter v-on:firm_change="filterByFirm" v-show="isGnhr" :inclGnhr="true"  />
                    </div>
                    <div class="col-sm-3">
                     <AssignFilter v-on:assigned_change="filterByStatus" :id="0" />
                    </div>
                    <div class="col-sm-3">
                      <div class="btn-group float-right" data-toggle="buttons" aria-label="">
                          <el-input v-model="tableFilteredData[0].value" placeholder="Search"
                              prefix-icon="el-icon-search" size="medium" style="width: 250px"></el-input>
                      </div>
                    </div>
                    <div class="col-sm-5 hidden-sm-down">
                        <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                          <el-dropdown @command="handleBulkAction">
                            <el-button class="ml-3" type="primary">
                              Bulk Upload<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item command="upload"> <i class="material-icons">cloud_upload</i>Upload file</el-dropdown-item>
                              <el-dropdown-item command="download">  <i class="material-icons">cloud_download</i>  Download template</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body p-4 pb-3">
              <data-tables-server
                :loading='busy'
                :data='tableData'
                :total='tableTotal'
                :filters="tableFilteredData"
                @query-change="get"
                :table-props='tableProps'>

                <el-table-column v-for="(item, idx) in visibleFields" :key="idx" :prop="item.prop" :label='item.label' :sortable='item.sortable' :width="item.width || ''">
                  <template slot-scope="scope">
                      <span v-if="item.prop === 'fullname'">
                          {{ scope.row.fullname ? scope.row[item.prop] : 'Not assigned' }}
                      </span>
                      <span v-else>{{ scope.row[item.prop] }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Actions">
                  <template slot-scope="scope">
                    <el-button v-if="isGnhr && !scope.row.firm_assigned" @click="showAssignKitFirm(scope.row.pkid, scope.row)" type="primary" size="mini">Assign kit to firm</el-button>
                    <el-button v-if="!isGnhr && !scope.row.enum_assigned" @click="showAssignKitEnum(scope.row.pkid, scope.row)" type="primary" size="mini">Assign kit</el-button>
                    <el-button v-if="!isGnhr && scope.row.enum_assigned && isAdmin" @click="unAssignKit(scope.row.pkid, scope.row)" type="primary" size="mini">Unassign kit</el-button>
                  </template>
                </el-table-column>

              </data-tables-server>
            </div>

          </div>
        </div>
    </div>

    <el-dialog title="Assign Kit"  :visible.sync="assignEnumDialogVisible" width="30%">
      <SelectDeviceOwners :id="selector_id" :value="enum_id" v-model="enum_id" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="assignEnumDialogVisible = false">Cancel</b-button>
        <b-button size="sm" class="" variant="primary" :disabled="kitEnumAssignDisabled" @click="assignKit()">Assign</b-button>
      </span>
    </el-dialog>

    <el-dialog title="Assign to Firm"  :visible.sync="assignDialogVisible" width="30%">
      <SelectFirm :id="selector_id" :value="firm_id" v-model="firm_id" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="assignDialogVisible = false">Cancel</b-button>
        <b-button size="sm" class="" variant="primary" :disabled="kitAssignDisabled" @click="assignKit()">Assign</b-button>
      </span>
    </el-dialog>

     <!-- Bulk modal -->
    <el-dialog width="40%" title="Upload Kits" :close-on-click-modal="false" :visible.sync="bulkDialogVisible" :before-close="handleClose">
      <vue-csv-import :url='bulkUploadUrl' :callback="uploadSuccess" :catch="uploadFailed" loadBtnText="Upload" submitBtnText="Submit" v-model="bulkCsv" :map-fields="bulkFields" :headers="true"></vue-csv-import>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose()">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { VueCsvImport } from 'vue-csv-import'
import { DataTablesServer } from 'vue-data-tables'
let numeral = require('numeral')

import { EndPoints } from '../../api/api'
import kitService from '../../api/devices'
import PageHeader from '../../containers/PageHeader'
import FirmFilter from '../../components/filters/FirmFilter'
import AssignFilter from '../../components/filters/AssignedFilter'
import SelectFirm from '../../components/selectors/SelectFirms'
import SelectDeviceOwners from '../../components/selectors/SelectDeviceOwners'

Vue.use(DataTablesServer)
Vue.use(require('vue-moment'))

export default {
  name: 'DeviceKit',
  components: {
    PageHeader, FirmFilter, AssignFilter, SelectFirm, SelectDeviceOwners, VueCsvImport
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      isAdmin: 'isAdmin',
      user: 'getUser',
      userFirm: 'getUserFirm',
      staff: 'getEnumeratorsAndSupervisorsUsingLinkId'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    title: function () {
      return 'Device Kits (' + numeral(this.tableTotal).format('0,0') + ')'
    },
    kitAssignDisabled: function () {
      return this.firm_id === 0 || is.null(this.firm_id)
    },
    kitEnumAssignDisabled: function () {
      return this.enum_id === 0 || is.null(this.enum_id)
    }
  },
  data () {
    return {
      busy: true,
      bulkDialogVisible: false,
      bulkUploadUrl: '',
      // bulkFields: { firm_id: 'Firm ID*', name: 'Enumerator*', enumeratorID: 'Enumerator ID*', Tablet_IMEI: 'Tablet_IMEI*', Scanner_Serial: 'Scanner Serial*',  PowerBank_Serial: 'Power Bank Serial*', Connector_Serial: 'Connector Serial', SimCard_ID: 'SIM Card ID' },
      bulkFields: { firm_id: 'Firm ID*', name: 'Enumerator*', enumeratorID: 'Enumerator ID*', Tablet_IMEI: 'Tablet Code*', Scanner_Serial: 'Scanner Code*',  PowerBank_Serial: 'Power Bank Code', Connector_Serial: 'Connector Code', SimCard_ID: 'SIM Card ID' },
      bulkCsv: [],
      sortProps: { order: 'ascending', prop: 'kitCode' },
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['kitCode', 'fullname', 'devices', 'fname'],
        }
      ],
      tableProps: { style: 'width: 99%', emptyText: 'No device kits found', stripe: true, defaultSort: this.sortProps },
      viewIgnoreFields: ['fullname', 'mobile', 'emailAddress', 'ezwich'],
      firmFilter: '',
      assignFilter: 0,
      firm_id: 0,
      enum_id: 0,
      selector_id: 0,
      selectedItem: null,
      assignDialogVisible: false,
      assignEnumDialogVisible: false,
      queryProps: { page: 1, pageSize: 10, sort: this.sortProps, search: '', firm: '', status: '' }
    }
  },
  created () {
    this.bulkUploadUrl = EndPoints.DEVICES_KIT_UPLOAD_URL
    this.assignFilter = 0
    this.firmFilter = this.isGnhr ? 0 : this.user.firm_id
    this.tableFields = [
      { prop: 'pkid', label: 'ID', visible: false, sortable: 'custom', width: '50px' },
      { prop: 'fname', label: 'Assigned to', visible: this.isGnhr, sortable: 'custom', width: '150px' },
      { prop: 'kitCode', label: 'Kit Code', visible: true, sortable: 'custom', width: '100px' },
      { prop: 'devices', label: 'Devices', visible: true, sortable: false, width: '550px'  },
      { prop: 'fullname', label: 'Assigned to', visible: (this.isAdmin && !this.isGnhr), sortable: true },
      { prop: 'firm_id', label: 'Firm', visible: false, sortable: true },
      { prop: 'firm_assigned', label: 'Assigned', visible: false, sortable: true },
      { prop: 'enum_assigned', label: 'Assigned', visible: false, sortable: true },
    ]
    this.get()
  },
  methods: {
    get (queryInfo = {}) {
      let self = this
      this.busy = true
      this.queryProps = queryInfo
      let params = this.getParams(queryInfo)
      kitService.getDeviceKits(params)
        .then((response) => {
          self.tableData = response.data.map(function (s) {
            return self.getSimpleRow(s)
          })
          self.tableTotal = response.meta.total
          self.busy = false
        })
        .catch((error) => {
          this.busy = false
          this.handleError(error)
        })
    },
    filterByStatus (v) {
      this.assignFilter = v.value
      this.get(this.queryProps)
    },
    filterByFirm (v) {
      this.firmFilter = v.value
      this.get(this.queryProps)
    },
    showAssignKitFirm (idx, item) {
      this.selector_id = idx
      this.firm_id = item.firm_assigned ? item.firm_id  : 0
      this.selectedItem = item
      this.assignDialogVisible = true
    },
    showAssignKitEnum (idx, item) {
      this.selector_id = idx
      this.enum_id = item.enum_assigned ? item.enumeratorID  : 0
      this.selectedItem = item
      this.assignEnumDialogVisible = true
    },
    assignKit () {
      let self = this
      let payload = this.isGnhr ? {
        id: this.selectedItem.pkid,
        firm_id: this.firm_id
      } : {
        id: this.selectedItem.pkid,
        enumeratorID: this.enum_id
      }
      kitService.updateDeviceKit(payload)
        .then(() => {
          self.enum_id = 0
          self.kit_id = 0
          self.selectedItem = null
          self.selector_id = null
          self.assignDialogVisible = false
          self.assignEnumDialogVisible = false
          self.showSuccess('Success', 'Kit updated')
          self.get()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    unAssignKit (item) {
      let self = this
      let payload = {
        id: item
      }
      kitService.unassignDeviceKit(payload)
        .then(() => {
          self.$store.dispatch('getEnumerators', { limit: -1, firm: self.user.firm_id })
          self.showSuccess('Success', 'Kit unassigned')
          self.get()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    getParams (info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : this.sortProps.prop,
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : this.sortProps.order,
        search: (info.type === 'filter') ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : '',
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        status: this.assignFilter === 0 ? '' : (this.isGnhr  ? 'firm_' + this.assignFilter : 'enum_' +  this.assignFilter)
      }
    },
    getSimpleRow (item) {
      let x = {}
      for(let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) && is.not.null(item[i.prop]) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && is.not.null(item[i.prop]) ? numeral(item[i.prop]).format('0,0') : x[i.prop]
      }
      return x
    },
    // Event handlers
    handleBulkAction(val) {
      if (val === 'upload') {
        this.bulkDialogVisible = true
      } else if (val === 'download') {
        this.downloadCsv()
      }
    },
    downloadCsv () {
      let self = this
      let csvContent = 'data:text/csv;charset=utf-8'
      for(let x in this.bulkFields) {
        csvContent += ',' + this.bulkFields[x]
      }
      csvContent += '\n'
      csvContent += this.staff.map(function (d) {
        return self.userFirm + ',' + d.label + ',' + d.id
      }).join('\n').replace(/(^\{)|(\}$)/mg, '')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'GNHR Device Kit Template.csv')
      document.body.appendChild(link)
      link.click()
    },
    uploadSuccess () {
      this.$message({ type: 'success', message: 'Upload successful.' })
      this.bulkDialogVisible = false
      this.get()
    },
    uploadFailed (e) {
      let msg = 'An error occured.'
      if (is.array(e.response.data.error)) {
        msg = e.response.data.error.join('<br><br/>')
      } else {
        msg = e.response.data.error
      }
      msg = 'Error uploading file:<br><br>' + msg
      this.$message({ dangerouslyUseHTMLString: true, type: 'error', message: msg, duration: 5000 })
    },
    handleClose(done) {
      if (is.function(done)) {
        done()
      }
      this.bulkCsv = []
      this.bulkDialogVisible = false
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white!important;
}
.text-light {
  color: white!important;
}
</style>