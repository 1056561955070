import { render, staticRenderFns } from "./DeviceKit.vue?vue&type=template&id=152cdc66&"
import script from "./DeviceKit.vue?vue&type=script&lang=js&"
export * from "./DeviceKit.vue?vue&type=script&lang=js&"
import style0 from "./DeviceKit.vue?vue&type=style&index=0&id=152cdc66&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports