import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'deviceService',
  components: {
    apiService
  },

  getDevices (params = {}) {
    return apiService.get(EndPoints.DEVICES_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getDevice (id) {
    let url = EndPoints.DEVICES_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addDevice (device) {
    let url = EndPoints.DEVICES_URL

    return apiService.create(url, device)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateDevice (device) {
    let url = EndPoints.DEVICES_URL + '/' + device.id

    return apiService.update(url, device)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeDevice (device) {
    let url = EndPoints.DEVICES_URL + '/' + device.id

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },


  getDeviceKits (params = {}) {
    return apiService.get(EndPoints.DEVICES_KIT_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getDeviceKit (id) {
    let url = EndPoints.DEVICES_KIT_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addDeviceKit (devicekit) {
    let url = EndPoints.DEVICES_KIT_URL

    return apiService.create(url, devicekit)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateDeviceKit (devicekit) {
    let url = EndPoints.DEVICES_KIT_URL + '/' + devicekit.id

    return apiService.update(url, devicekit)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  unassignDeviceKit (devicekit) {
    let url = EndPoints.DEVICES_KIT_URL + '/unassign/' + devicekit.id

    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeDeviceKit (devicekit) {
    let url = EndPoints.DEVICES_KIT_URL + '/' + devicekit.id

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
