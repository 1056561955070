<template>
  <div>
      <div v-show="busy">
        <b-spinner small class="ml-5" variant="primary" label="Loading devices"/>
      </div>
      <div v-show="!busy">
        <b-form-group id="user_combo" label="User" :label-cols="lpos" label-for="user_select" :state="null">
            <template v-slot:label>
              <b>Enumerator</b>
            </template>
            <span v-if="options.user.length > 0">
              <model-list-select id="user_select" name="user_select" :list="options.user" v-model="selected.user" placeholder="Select Enumerator" option-value="id" option-text="label"></model-list-select>
            </span>
            <span v-else style="color:red">{{ this.enumerators.length > 0 ? 'All users have been assigned devices' : 'No users available to assign to.' }}</span>
        </b-form-group>
        <b-form-group id="tablet_combo" label="Tablets" :label-cols="lpos" label-for="tablet_select" :state="null">
            <template v-slot:label>
              <b>Tablet</b>
            </template>
            <model-list-select id="tablet_select" name="tablet_select" :list="options.tablet" v-model="selected.tablet" placeholder="Select Tablet" option-value="id" option-text="label"></model-list-select>
        </b-form-group>
        <b-form-group id="s_combo" label="Scanner" :label-cols="lpos" label-for="s_select" :state="null">
            <template v-slot:label>
              <b>Scanner</b>
            </template>
            <model-list-select id="s_select" name="s_select" :list="options.scanner" v-model="selected.scanner" placeholder="Select Scanner" option-value="id" option-text="label"></model-list-select>
        </b-form-group>
        <b-form-group id="p_combo" label="Power Banks" :label-cols="lpos" label-for="p_select" :state="null">
            <template v-slot:label>
              <b>Power Bank</b>
            </template>
            <model-list-select id="p_select" name="p_select" :list="options.powerbank" v-model="selected.powerbank" placeholder="Select Powerbank" option-value="id" option-text="label"></model-list-select>
        </b-form-group>
        <b-form-group id="u_combo" label="USB" :label-cols="lpos" label-for="u_select" :state="null">
            <template v-slot:label>
              <b>USB</b>
            </template>
            <model-list-select id="u_select" name="u_select" :list="options.usb" v-model="selected.usb" placeholder="Select USB" option-value="id" option-text="label"></model-list-select>
        </b-form-group>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import api from '../../api/devices'
import apiService from '../../api/user'
import is from 'is_js'

export default {
  name: 'SelectDevicesMultiple',
  components: {
    ModelListSelect
  },
  props: {
    opening: { type: Boolean, default: false },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    busy: false,
    enumerators: [],
    selected: {
      user: null,
      tablet: null,
      scanner: null,
      powerbank: null,
      usb: null
    },
    options: {
      user: [],
      tablet: [],
      scanner: [],
      powerbank: [],
      usb: []
    }
  }),
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    opening: function (v) {
      if (v) {
        this.init()
      }
    },
    selected: {
      handler() {
        this.sendUpdate()
      },
      deep: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.selected = {
        user: null,
        tablet: null,
        scanner: null,
        powerbank: null,
        usb: null
      }
      this.busy = true
      this.getEnumerators()
      this.getDevices()
    },
    sendUpdate () {
      let payload = {}
      if (is.not.null(this.selected.user) && this.selected.user !== '') {
        payload['enumeratorID'] =  this.selected.user
      }
      if (is.not.null(this.selected.tablet) && this.selected.tablet !== '') {
        payload['Tablet_IMEI'] =  this.selected.tablet
      }
      if (is.not.null(this.selected.scanner) && this.selected.scanner !== '') {
        payload['Scanner_Serial'] =  this.selected.scanner
      }
      if (is.not.null(this.selected.powerbank) && this.selected.powerbank !== '') {
        payload['PowerBank_Serial'] =  this.selected.powerbank
      }
      if (is.not.null(this.selected.usb) && this.selected.usb !== '') {
        payload['Connector_Serial'] =  this.selected.powerbank
      }
      this.$emit('input', payload)
    },
    getDevices () {
      let self = this
      this.busy = true
      api.getDevices({ limit: -1 })
        .then((response) => {
          self.options.tablet = self.setDevices(response, 1)
          self.options.scanner = self.setDevices(response, 3)
          self.options.powerbank = self.setDevices(response, 4)
          self.options.usb = self.setDevices(response, 5)
          self.busy = false
        })
        .catch((error) => {
          self.busy = false
          this.handleError(error)
        })
    },
    getEnumerators () {
      let self = this
      this.enumerators = []
      return apiService.getUsers({ limit: -1, firm: this.user.firm_id })
        .then((response) => {
          self.enumerators = response.data
          self.options.user = response.data.filter(function (e) {
            return !e.has_device && (e.role === 'Enumerator' || e.role === 'Supervisor')
          }).map(function (e) {
            return { id: e.linkId, label: e.fullname, link: e.linkId, has_device: e.has_device }
          })
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
    },
    setDevices (response, typeId) {
      return response.data.filter(function (e) {
        return is.null(e.kitCode) && e.Type === typeId && e.Assigned === 0
      }).map(function (e) {
        return { id: e.ID, label: e.device_type + ' - ' + e.GNHRCode + ' - ' + e.Serial }
      }).sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    }
  }
}
</script>
