<template>
  <div>
      <div v-show="busy">
        <b-spinner small class="ml-5" variant="primary" label="Loading people.."/>
      </div>
      <div v-show="!busy">
        <div v-if="options.length <= 0">
          All supervisors and enumerators have been assigned a device kit.
        </div>
        <div v-else>
          <b-form-group
            id="workers_combo"
            description=""
            label="Person"
            :label-cols="lpos"
            label-for="workers_select"
            :state="null">
              <template v-slot:label>
                <b>Owner</b>
              </template>
              <model-list-select id="person_select" name="person_select" :list="options" v-model="selected" placeholder="Select" option-value="id" option-text="label"></model-list-select>
          </b-form-group>
        </div>
      </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectDeviceOwners',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number },
    value: { type: [Number, String], default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    busy: false,
    selected: null,
    options: [],
  }),
  computed: {
    ...mapGetters({
      enums: 'getEnumerators',
      sups: 'getSupervisors'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    selected: function() {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.value
    this.setOptions()
  },
  methods: {
    sendUpdate() {
      this.$emit('input', this.selected)
      this.setOptions()
    },
    setOptions () {
      let self = this
      this.busy = true

      let e = this.enums.filter(function (e) {
        return !e.has_device
      }).map(function (e) {
        return { id: e.linkId, label: e.fullname }
      })

      let s = this.sups.filter(function (e) {
        return !e.has_device
      }).map(function (e) {
        return { id: e.linkId, label: e.fullname }
      })

      self.options = e.concat(s).sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
      self.busy = false
    }
  }
}
</script>
