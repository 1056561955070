<template lang="pug">
    extends ../BaseView.pug
</template>

<script>
import Vue from 'vue'
import BaseView from '../BaseView'

import DeviceList from './DeviceList'
import DeviceKit from './DeviceKit'

Vue.component('deviceKit', { template: '<DeviceKit />' })
Vue.component('deviceList', { template: '<DeviceList />' })

export default {
  name: 'Devices',
  extends: BaseView,
  components: { DeviceList, DeviceKit },
  props: {
    pages: {
      type: Array,
      default: () => {
        return [
          {
            tabName: 'kits',
            component: 'deviceKit'
          },
          {
            tabName: 'list',
            component: 'deviceList'
          }
        ]
      }
    }
  }
}
</script>
