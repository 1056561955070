<template>
  <div>
      <div v-show="busy">
        <b-spinner small class="ml-5" variant="primary" label="Loading firms.."/>
      </div>
      <div v-show="!busy">
      <b-form-group
        id="firms_combo"
        description=""
        label="Firm"
        :label-cols="lpos"
        label-for="firm_select"
        :state="null">
          <template v-slot:label>
             <b>Firm</b>
          </template>
          <model-list-select id="firm_select" name="firm_select" :list="options" v-model="selected" placeholder="Select Firm" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
      </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import api from '../../api/firm'

export default {
  name: 'SelectFirm',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number },
    value: { type: Number, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    busy: false,
    selected: null,
    options: [],
  }),
  computed: {
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    id: function () {
      // this.getKits()
    },
    selected: function() {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.value
    this.getFirms()
  },
  methods: {
    sendUpdate() {
      this.$emit('input', this.selected)
      this.getFirms()
    },
    getFirms () {
      let self = this
      this.busy = true
      api.getFirms({ limit: -1 })
        .then((response) => {
          self.options = response.data.filter(function (e) {
            return e.id !== 1
          }).map(function (e) {
            return { id: e.id, label: e.name }
          }).sort(function (a, b) {
            return a.label.localeCompare(b.label)
          })
          self.busy = false
        })
        .catch((error) => {
          self.busy = false
          this.handleError(error)
        })
    }
  }
}
</script>
