<template>
  <div>
      <b-form-group
        id="assigned_combo"
        description=""
        label="Status"
        :label-cols="lpos"
        label-for="assigned_select"
        :state="null">
          <template v-slot:label>
             <b>Status</b>
          </template>
          <model-list-select id="assigned_select" name="assigned_select" :list="options" v-model="selected" placeholder="Select Assign" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'AssignedFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: [String, Number], default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: '',
    options: [
      { id: 0, label: 'All' },
      { id: 'assigned', label: 'Assigned' },
      { id: 'unassigned', label: 'Unassigned' }
    ]
  }),
  computed: {
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.id
  },
  methods: {
    sendUpdate () {
      if (!this.first) {
        this.$emit('assigned_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
